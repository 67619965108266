import { useCallback, useEffect } from 'react';

import { useAppDispatch } from '@/Hooks/useAppDispatch';
import { useAppSelector } from '@/Hooks/useAppSelector';
import { PartnerServiceFactory } from '@/Services';
import { AuthActions, AuthSelectors } from '@/Store/Auth';
import { DataFetchingStatus } from '@/Types';

export const Authenticate = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, fetchingStatus } = useAppSelector(AuthSelectors.authState);

  const getPartnerRoles = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const partnerServiceInstance = new PartnerServiceFactory().getInstance(dispatch);
      const partnerRoles = await partnerServiceInstance.getPartnerRoles();
      dispatch(AuthActions.setPartnerRoles(partnerRoles));
    } catch (error) {
      console.error('User not authenticated: ', error);
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    getPartnerRoles();
  }, [getPartnerRoles]);

  useEffect(() => {
    dispatch(AuthActions.Authenticate());
  }, [dispatch]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      (fetchingStatus === DataFetchingStatus.FulFilled ||
        fetchingStatus === DataFetchingStatus.HasError)
    ) {
      localStorage.removeItem('publicId');
      localStorage.removeItem('vpp-language');
      sessionStorage.removeItem('ResourceAuth');
    }
  }, [fetchingStatus, isAuthenticated]);
  return <></>;
};
