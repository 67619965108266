import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useAddAnotherStudioModalStyles = makeStyles()(() => ({
  dialogRoot: {
    '& .MuiBackdrop-root': {
      opacity: '0.8!important',
      backgroundImage: `linear-gradient(171deg, ${FigmaDesignTokens.Blue[200]} 27%, ${FigmaDesignTokens.Blue[800]} 109%)`,
    },
  },
  personImage: {
    position: 'absolute',
    top: -116,
    width: 418,
    left: 526,
    pointerEvents: 'none',
  },
}));
