import { Navigate, Route, Routes } from 'react-router-dom';

import { SidebarNavGuest } from '@/Components';
import { ForgotPasswordPage } from '@/Views/Authentication/ForgotPasswordPage';
import InitialPasswordPage from '@/Views/Authentication/InitialPasswordPage';
import { LoginPage } from '@/Views/Authentication/LoginPage';
import { RegisterPartnerPage } from '@/Views/Authentication/RegisterPartnerPage';
import { RegisterUserPage } from '@/Views/Authentication/RegisterUserPage';

import { TwoRowsWithNavGuestLayout } from './App/Shared/Layout/TwoRowsWithNavGuestLayout';
import { EmailConfirmationPage } from './Views/Authentication/EmailConfirmationPage';

export const NotAuthenticatedRoutes = () => (
  <Routes>
    <Route
      path='/register'
      element={
        <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
          <RegisterPartnerPage />
        </TwoRowsWithNavGuestLayout>
      }
    />

    <Route
      path='/initial-password'
      element={
        <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
          <InitialPasswordPage />
        </TwoRowsWithNavGuestLayout>
      }
    />

    <Route
      path='/login'
      element={
        <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
          <LoginPage />
        </TwoRowsWithNavGuestLayout>
      }
    />

    <Route
      path='/forgot-password'
      element={
        <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
          <ForgotPasswordPage />
        </TwoRowsWithNavGuestLayout>
      }
    />
    <Route
      path='/'
      element={
        <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
          <EmailConfirmationPage emailConfirmed />
        </TwoRowsWithNavGuestLayout>
      }
    />
    <Route
      path='/:language/self-registration'
      element={
        <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
          <RegisterUserPage />
        </TwoRowsWithNavGuestLayout>
      }
    />
    {/* <Route
      path='/:language/datenschutz'
      element={
        <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
          <DataPrivacy />
        </TwoRowsWithNavGuestLayout>
      }
    /> */}

    <Route path='*' element={<Navigate to='/login' />} />
  </Routes>
);
