import { Box, Button, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { BodyTextSmall, MainHeadline } from '@/Components';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { RegistrationUserPasswordValidation } from '@/Utils';

import { RegisterPhotos } from '../../Shared/Photos/RegisterPhotos';
import { useUserFormStyles } from '../RegisterUser.styles';

export type CreateUserPasswordStepProps = {
  savePasswordsData: (values: { password: string; passwordConfirmation: string }) => void;
  handleReset: () => void;
};

const CreateUserPassword = ({ savePasswordsData, handleReset }: CreateUserPasswordStepProps) => {
  const { formatMessage } = useMemoedIntl();
  const { classes } = useUserFormStyles();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const sanitizedLegalHint = useMemo(
    () =>
      sanitize(
        formatMessage({
          id: 'form.register.stepper.step.initial_startcode.legal_hint',
          defaultMessage:
            'By clicking on "Go ahead", I declare that I have taken note of the content',
        }),
      ),
    [formatMessage],
  );
  return (
    <>
      <Formik
        validationSchema={RegistrationUserPasswordValidation}
        validateOnChange
        validateOnBlur={false}
        validate={() => {
          setSubmitDisabled(false);
        }}
        enableReinitialize
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        onSubmit={savePasswordsData}>
        {formik => {
          const isFullFormDataSubmit =
            isEmpty(formik.values.password) || isEmpty(formik.values.passwordConfirmation);

          const isDisabledSubmit =
            submitDisabled || (!formik.isValid && isEmpty(formik.errors)) || isFullFormDataSubmit;

          return (
            <Form>
              <ConnectedFocusError />
              <Box className={classes.mainContent}>
                <Grid container gap={4} textAlign='center'>
                  <Grid item width='100%'>
                    <MainHeadline
                      textContent={formatMessage({
                        id: 'form.register_user.header',
                        defaultMessage: 'User registration',
                      })}
                    />
                    <BodyTextSmall
                      textAlign='center'
                      textContent={formatMessage({
                        id: 'form.set_new_password.hint',
                        defaultMessage: 'Please, create a new password.',
                      })}
                    />
                  </Grid>
                  <Grid container item width='100%' gap={2}>
                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        component={FormikTextField}
                        variant='outlined'
                        name='password'
                        type='password'
                        label={
                          <FormattedMessage
                            id='form.register_user.field.password.label'
                            defaultMessage='new password*'
                          />
                        }
                      />
                      <FormikErrorMessage name='password' />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        component={FormikTextField}
                        variant='outlined'
                        name='passwordConfirmation'
                        type='password'
                        label={
                          <FormattedMessage
                            id='form.register_user.field.password_repeat.label'
                            defaultMessage='repeat new password*'
                          />
                        }
                      />
                      <FormikErrorMessage name='passwordConfirmation' />
                    </Grid>
                  </Grid>

                  <Grid>
                    <Typography
                      className={classes.legalHint}
                      dangerouslySetInnerHTML={{
                        __html: sanitizedLegalHint,
                      }}
                    />
                  </Grid>

                  <Grid item container xs={12} justifyContent='space-between' direction='row'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleReset}
                      className={clsx(classes.mainActionBtn, classes.userBtn)}>
                      {formatMessage({
                        id: 'form.register_user.cta.back',
                        defaultMessage: 'Back',
                      })}
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={isDisabledSubmit}
                      className={clsx(classes.mainActionBtn, classes.userBtn)}>
                      {formatMessage({
                        id: 'form.register_user.cta.finish_registration',
                        defaultMessage: 'Complete registration',
                      })}
                    </Button>
                  </Grid>
                  <RegisterPhotos />
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateUserPassword;
