import 'react-image-crop/dist/ReactCrop.css';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Button } from '@/Components';
import { ConfirmationDialogTypes, useAppContext } from '@/Context';
import { CropMap, getDefaultFilename } from '@/Mappers';
import { AssetServiceFactory } from '@/Services';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import CropIcon from '@/Static/Icons/atoms-symbols-icons-media-crop.svg';
import { GalleryAsset, Partner } from '@/Types';

import { useCropGalleryImageStyles } from './Styles/CropGalleryImage.modal';

type CropGalleryImageModalProps = {
  resourceData: GalleryAsset;
  casPublicId: Partner['casPublicId'];

  handlePrimaryCtaDisabled: (value: boolean) => void;
};

export const CropGalleryImageModal = ({
  resourceData,
  casPublicId,

  handlePrimaryCtaDisabled,
}: CropGalleryImageModalProps) => {
  const { classes } = useCropGalleryImageStyles();
  const { dispatch } = useAppContext();
  const intl = useIntl();
  const cropService = useMemo(() => new CropMap(), []);
  const [open, setOpen] = useState(false);
  const [signedResource, setSignedResource] = useState<string>();
  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);
  const [crop, setCrop] = useState<Partial<Crop>>({ aspect: 16 / 9 });

  const [croppedImageElement, setCroppedImageElement] = useState<HTMLImageElement>();
  const formik = useFormikContext<{ gallery: GalleryAsset[] }>();

  const [disabled, setDisabled] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleSave = useCallback(async () => {
    if (signedResource && cropService && resourceData) {
      setDisabled(true);
      const croppedImageAsBlob = await cropService.getCroppedImgAsBlob(croppedImageElement, crop);

      if (croppedImageAsBlob) {
        let newAssetId;

        const filename = getDefaultFilename(resourceData.uri || '');

        await assetService.S3Upload(
          casPublicId,
          filename,
          'Gallery',
          new File([croppedImageAsBlob], filename),
          () => {},
          err => {
            if (err) {
              console.error(err);
              return;
            }

            assetService
              .assignGalleryImage(casPublicId, {
                filename,
                originalFilename: resourceData.originalFilename,
              })
              .then(response => {
                newAssetId = response.data.assetId;
                handlePrimaryCtaDisabled(false);

                const gallery = formik.values.gallery;
                const galleryIndex = gallery.findIndex(
                  asset => asset.assetId === resourceData.assetId,
                );

                if (galleryIndex !== -1 && newAssetId) {
                  gallery[galleryIndex] = {
                    ...gallery[galleryIndex],
                    assetId: newAssetId,
                    uri: filename,
                  };

                  formik.setValues({ gallery: [...gallery] });
                  formik.setFieldTouched('gallery', true);
                  setOpen(false);
                  dispatch({
                    type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
                    payload: { show: true },
                  });
                  handlePrimaryCtaDisabled(false);
                }
              });
          },
        );
      }
      setDisabled(false);
    }
  }, [
    assetService,
    casPublicId,
    crop,
    cropService,
    croppedImageElement,
    dispatch,
    formik,
    handlePrimaryCtaDisabled,
    resourceData,
    signedResource,
  ]);

  const getSignedResource = useCallback(async () => {
    if (resourceData.uri) {
      const signedResource = await assetService.S3GetSignedResource(
        casPublicId,
        resourceData.uri,
        'Gallery',
      );

      if (signedResource) {
        setSignedResource(signedResource);
      }
    }
  }, [assetService, casPublicId, resourceData.uri]);

  useEffect(() => {
    getSignedResource();
  }, [getSignedResource]);

  return (
    <Box className={classes.cropWrapper}>
      <Button className={classes.btnModal} onClick={handleOpen} variant='text'>
        <img alt='' src={CropIcon} />
        <Typography variant='body2' className={classes.btnText}>
          <FormattedMessage
            id='form.photo_and_video.photo_upload.image.cta.edit_image_detail'
            defaultMessage='Edit image section'
          />
        </Typography>
      </Button>
      <Dialog
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            padding: '50px 30px 30px 30px',
          },
        }}>
        <Box className={classes.dialogTitle}>
          <DialogTitle>
            <FormHeader
              title={intl.formatMessage({
                id: 'form.photo_and_video.photo_upload.image.cta.edit_image_detail',
                defaultMessage: 'Edit image section',
              })}
            />
          </DialogTitle>
          <IconButton aria-label='close' onClick={handleClose} className={classes.closeButton}>
            <img src={CloseIcon} alt='' />
          </IconButton>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Grid item xs={12}>
              {signedResource && (
                <ReactCrop
                  src={signedResource}
                  crop={crop}
                  onImageLoaded={setCroppedImageElement}
                  onChange={newCrop => setCrop(newCrop)}
                  crossorigin='anonymous'
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button disabled={disabled} autoFocus onClick={handleSave} className={classes.cta}>
            <FormattedMessage
              id='form.photo_and_video.photo_upload.image.cta.submit_edit_image_detail'
              defaultMessage='Apply changes'
            />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
