import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { RegisterPartnerServiceFactory } from '@/Services';
import handleCatchError from '@/Utils/handleCatchError';

type useGetRegistrationDataQueryProps = {
  signupKeyId: string | null;
  partnerId: string | null;
};

const useGetRegistrationDataQuery = ({
  signupKeyId,
  partnerId,
}: useGetRegistrationDataQueryProps) => {
  const { dispatch } = useAppContext();

  const registerPartnerService = useMemo(
    () => new RegisterPartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const queryResult = useQuery(
    ['getRegistrationData', signupKeyId, partnerId],
    async () => {
      if (!partnerId || !signupKeyId) {
        return;
      }
      const resp = await registerPartnerService.getNewUserData(partnerId, signupKeyId);
      return resp.data.detail;
    },
    {
      onError: handleCatchError,
      enabled: !!partnerId && !!signupKeyId,
      staleTime: 1000 * 60 * 5,
    },
  );

  return queryResult;
};

export default useGetRegistrationDataQuery;
