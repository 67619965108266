import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SpinnerLoader } from '@/Components';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import useGetRegistrationDataQuery from '@/Queries/SelfRegistration/useGetRegistrationDataQuery';
import { RegisterPartnerServiceFactory } from '@/Services';
import { SelfRegistration, SelfRegistrationFormProps } from '@/Types';
import { SdkErrorType } from '@/Types/SdkError';

import { ConfirmationStep } from '../RegisterPartner/Steps/ConfirmationStep';
import CreateUserPassword from './Steps/CreateUserPassword';
import ExpiredRegistrationLink from './Steps/ExpiredRegistrationLink';
import ModifyUserDataForm from './Steps/ModifyUserDataForm';

const RegisterUserForm = () => {
  const [step, setStep] = useState(0);
  const [userData, setUserData] = useState({ email: '', name: '', partnerId: '', surname: '' });
  const dispatch = useAppDispatch();
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const signupKeyId = useMemo(() => searchParams.get('signupKey'), [searchParams]);
  const partnerId = useMemo(() => searchParams.get('partnerId'), [searchParams]);

  const {
    data: prefilledUserData,
    isError,
    isLoading,
  } = useGetRegistrationDataQuery({
    signupKeyId,
    partnerId,
  });
  const registerPartnerService = new RegisterPartnerServiceFactory().getInstance(dispatch);

  const [asyncErrorMessage, setAsyncErrorMessage] = useState('');

  const handleReset = useCallback(() => {
    setStep(0);
  }, [setStep]);

  const handleSubmit = useCallback(
    async (values: SelfRegistrationFormProps) => {
      if (!signupKeyId || !partnerId) {
        return;
      }
      setAsyncErrorMessage('');
      try {
        await registerPartnerService.registerAdminUser({
          signupKey: signupKeyId,
          partnerId,
          email: values.email,
          name: values.name,
          surname: values.surname,
          password: values.password,
        });
        setStep(2);
      } catch (e: unknown) {
        const sdkError = e as SdkErrorType;
        setAsyncErrorMessage(sdkError.message);
        handleReset();
      }
    },
    [registerPartnerService, signupKeyId, partnerId, handleReset],
  );

  const savePasswordsData = useCallback(
    (values: { password: string; passwordConfirmation: string }) => {
      handleSubmit({ ...userData, ...values });
    },
    [userData, handleSubmit],
  );

  const saveUserData = useCallback(
    (values: SelfRegistration) => {
      setUserData(values);
      setStep(1);
    },
    [setUserData],
  );

  if (isError) {
    return <ExpiredRegistrationLink />;
  }
  if (isLoading || !prefilledUserData) {
    return <SpinnerLoader isFullScreen />;
  }
  if (step === 1) {
    return <CreateUserPassword savePasswordsData={savePasswordsData} handleReset={handleReset} />;
  }
  if (step === 2) {
    return <ConfirmationStep email={userData.email} showHeader={false} isEmailConfirmed={false} />;
  }
  return (
    <>
      <ModifyUserDataForm
        saveUserData={saveUserData}
        userData={prefilledUserData}
        asyncErrorMessage={asyncErrorMessage}
      />
    </>
  );
};

export default RegisterUserForm;
