import { Box } from '@mui/material';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import SidebarNavItemStyles from '../Styles/SidebarNavItem.styles';

type LeftNavigationItemProps = {
  title: string;
  href: string;
  isGroupHeader?: boolean;
  navLevel?: 'group-header' | 'subheader' | 'nav-item';
};

export const MenuNavigationItem = ({
  title,
  href,
  isGroupHeader,
  navLevel,
}: LeftNavigationItemProps) => {
  const { classes } = SidebarNavItemStyles();

  const location = useLocation();
  const isActive = !isGroupHeader
    ? location.pathname === href
    : location.pathname.slice(0, location.pathname.lastIndexOf('/')) ===
      href.slice(0, href.lastIndexOf('/'));
  const isBottomLevel = navLevel === 'nav-item';

  return (
    <Link
      to={href}
      className={clsx(classes.navigationListItemContainer, {
        [classes.navigationListItemContainerActive]: isActive,
        [classes.subItemContainer]: isBottomLevel && isActive,
        [classes.subItemLink]: isBottomLevel,
      })}>
      <Box
        className={clsx(classes.navigationListItem, {
          [classes.navigationListItemActive]: isActive,
          [classes.subItem]: isBottomLevel,
        })}>
        {title}
      </Box>
    </Link>
  );
};
