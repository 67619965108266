import { Box, Stack } from '@mui/material';

import { BodyTextSmall, LineDivider, MainHeadline } from '@/Components';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import Warning from '@/Static/Icons/atoms-symbols-alert-error.svg';

import { LoginPhotos } from '../../Login/LoginPhotos';
import { useUserFormStyles } from '../RegisterUser.styles';

const ExpiredRegistrationLink = () => {
  const { formatMessage } = useMemoedIntl();
  const { classes } = useUserFormStyles();

  return (
    <Box className={classes.mainContent}>
      <Stack alignItems='center'>
        <img alt='' src={Warning} width='32px' />
        <MainHeadline
          textContent={formatMessage({
            id: 'form.register.stepper.error.header',
            defaultMessage: 'Something went wrong.',
          })}
        />
      </Stack>
      <LineDivider verticalSpace={12} />
      <BodyTextSmall
        textAlign='center'
        textContent={formatMessage({
          id: 'form.register.stepper.error.expired',
          defaultMessage: 'The verification link has unfortunately expired.',
        })}
      />
      <LineDivider verticalSpace={12} />
      <BodyTextSmall
        textAlign='center'
        textContent={formatMessage({
          id: 'form.register.stepper.error.text',
          defaultMessage: 'Please contact us to get a new link.',
        })}
      />
      <LoginPhotos />
    </Box>
  );
};

export default ExpiredRegistrationLink;
