import { AxiosResponse } from 'axios';

import { EnvironmentService, HttpService } from '@/Services';
import {
  AddPartnerResponseType,
  IsValidPartnerResponseType,
  RegisterPartnerResponseType,
  ResendCodeResponseType,
  SelfRegistrationResponseType,
  VerifyUserResponseType,
} from '@/Types';
import { SdkErrorType } from '@/Types/SdkError';

export class RegisterPartnerService {
  public httpService: HttpService;
  public environmentService: EnvironmentService;

  constructor(httpService: HttpService, environmentService: EnvironmentService) {
    this.httpService = httpService;
    this.environmentService = environmentService;
  }

  public async registerPartner(params: {
    partnerId: string;
    email: string;
    password: string;
    name: string;
    surname: string;
    startCode: string;
  }): Promise<AxiosResponse<RegisterPartnerResponseType>> {
    const axiosInstance = await this.httpService.getAxios();
    return await axiosInstance.post(
      this.environmentService.getAwsRegisterUserBasePath() + 'registerPartner',
      params,
    );
  }

  public async addPartner(params: {
    partnerId: string;
    startCode: string;
  }): Promise<AxiosResponse<AddPartnerResponseType>> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    return await axiosInstance.put(
      this.environmentService.getAwsRegisterUserBasePath() + 'addPartner',
      params,
    );
  }

  public async verifyUser(params: {
    email: string;
    verificationCode: string;
  }): Promise<AxiosResponse<VerifyUserResponseType>> {
    const axiosInstance = await this.httpService.getAxios();
    return await axiosInstance.put(
      this.environmentService.getAwsRegisterUserBasePath() + 'verifyUser',
      params,
    );
  }

  public async resendCode(params: {
    email: string;
  }): Promise<AxiosResponse<ResendCodeResponseType>> {
    const axiosInstance = await this.httpService.getAxios();
    return await axiosInstance.put(
      this.environmentService.getAwsRegisterUserBasePath() + 'resendCode',
      params,
    );
  }

  public async resendTempPassword(params: {
    publicId: string;
    email: string;
  }): Promise<AxiosResponse<ResendCodeResponseType>> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    return await axiosInstance.put(
      this.environmentService.getAwsRegisterUserBasePath() + 'resendTempPassword',
      params,
    );
  }

  public async isValid(
    partnerId: string,
    startCode: string,
  ): Promise<AxiosResponse<IsValidPartnerResponseType>> {
    const axiosInstance = await this.httpService.getAxios();

    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/validate_partner_id?partner_id=${partnerId}&start_code=${startCode}`,
    );
  }

  public async getNewUserData(
    partnerId: string,
    signupKeyId: string,
  ): Promise<AxiosResponse<SelfRegistrationResponseType>> {
    const axiosInstance = await this.httpService.getAxios();

    return await axiosInstance.get(
      `${process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT}getSignupKeyData/${partnerId}/${signupKeyId}`,
    );
  }

  public async registerAdminUser(data: {
    signupKey: string;
    password: string;
    email: string;
    partnerId: string;
    name: string;
    surname: string;
  }): Promise<AxiosResponse<{ statusCode: number; status: string; message: string }>> {
    const axiosInstance = await this.httpService.getAxios();

    return await axiosInstance.post(
      `${process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT}registerAdminUser`,
      data,
    );
  }

  public getTranslateMessageKey(code: SdkErrorType['code']) {
    return 'user_management.form.error.' + code;
  }

  public isDefaultMove(e: any): boolean {
    return (
      e.key === 'Shift' ||
      e.key === 'Tab' ||
      e.key === 'Enter' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowLeft'
    );
  }

  public isNext(e: any) {
    const target = e.target;
    const maxLength = +target.attributes['maxlength'].value;
    const myLength = target.value.length;
    return myLength >= maxLength;
  }

  public isPrev(e: any, prevValue: string) {
    return e.key === 'Backspace' && prevValue.length <= 1;
  }
}
