import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Theme from '@/App/Theme/Theme';
import { BodyTextRegular } from '@/Components/Typography';

import { useMenuNavStyles } from './MenuNavigation.styles';

type MenuNavItemHeaderProps = {
  className?: string;
  isActive: boolean;
  menuIcon: ReactNode;
  title: string;
} & ( // if isExpandable is true, require onClick prop // if isExpandable is false, require href prop
  | { isExpandable: false; onClick?: never; href: string }
  | { isExpandable: true; onClick: () => void; href?: never }
);

const MenuNavItemHeaderContainer = ({
  isActive,
  className,
  onClick,
  href,
  children,
}: Pick<MenuNavItemHeaderProps, 'isActive' | 'className' | 'onClick' | 'href'> & {
  children: ReactNode;
}) => {
  const { classes } = useMenuNavStyles();
  const setActiveNavStyle = (isItemSelected: boolean) => (isItemSelected ? classes.opaque : '');

  if (href) {
    return (
      <Link
        to={href}
        className={clsx(classes.navigationHeaderWrapper, setActiveNavStyle(isActive), className)}>
        {children}
      </Link>
    );
  }

  return (
    <Box
      className={clsx(classes.navigationHeaderWrapper, setActiveNavStyle(isActive), className)}
      onClick={onClick}>
      {children}
    </Box>
  );
};

const MenuNavItemHeader = ({
  menuIcon,
  isActive,
  isExpandable,
  title,
  className,
  onClick,
  href,
}: MenuNavItemHeaderProps) => {
  const { classes } = useMenuNavStyles();

  return (
    <MenuNavItemHeaderContainer
      {...{
        isActive,
        className,
        onClick,
        href,
      }}>
      <Box
        className={clsx(classes.navigationListItemRectangle, {
          [classes.navigationListItemActiveRectangle]: isActive,
        })}
      />
      <Box className={classes.navigationHeaderTitle}>
        {menuIcon}
        <BodyTextRegular
          textContent={title}
          fontWeight={500}
          customColor={Theme.palette.secondary.light}
        />
      </Box>
      {isExpandable && <ArrowDropDownIcon className={isActive ? classes.dropdownArrow : ''} />}
    </MenuNavItemHeaderContainer>
  );
};

export default MenuNavItemHeader;
