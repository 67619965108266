import { IntlShape } from 'react-intl';

export enum Role {
  ADMIN = 'Admin',
  User = 'User',
}

export type User = {
  publicId: string;
  role: Role;
  email: string;
  name: string;
  givenName: string;
  cognitoId: string;
  status: string;
};

export type UserModalChildContentProps = {
  description: string;
  givenName: string;
  name: string;
};

export type UserModalProps = {
  givenName: string;
  name: string;
  intl: IntlShape;
};

export type UserRegistrationFormProps = {
  email: string;
  temporaryPassword: string;
  password: string;
  passwordConfirmation: string;
};

export type SelfRegistrationFormProps = {
  email: string;
  name: string;
  partnerId: string;
  password: string;
  passwordConfirmation: string;
  surname: string;
};

export type ChangePasswordFormProps = {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};
