import { Clear, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { getInitialPasswordPathname } from '@/Mappers';
import { PartnerServiceFactory } from '@/Services';
import { AuthActions } from '@/Store/Auth';
import { LoginValidation } from '@/Utils';

import { FormikErrorMessage } from '../../../../App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '../../../../App/Shared/Form/Components/Formik/FormikTextField';
import FigmaDesignTokens from '../../../../design/design-tokens.json';
import { useAppDispatch } from '../../../../Hooks/useAppDispatch';

export interface SignInFormProps {
  variant: number;
  email?: string;
  password?: string;
}

const useStyles = makeStyles()(theme => ({
  mainActionBtn: {
    height: 36,
    marginTop: 40,
    marginBottom: 14,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    '&:disabled': {
      background: FigmaDesignTokens.Grey[200],
      color: theme.palette.info.dark,
    },
  },
  userBtn: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.background.default,
    padding: '7px 60px',
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textField: {
    '& label': {
      marginTop: '1px',
    },
    '& input': {
      height: 23,
    },
    '& label, input': {
      fontSize: 14,
    },
    '& label.Mui-error': {
      fontSize: 14,
    },
  },
  forgetBtn: {
    height: 22,
    color: theme.palette.primary.dark,
    marginBottom: 38,
  },
  error: {
    fontSize: 14,
    color: theme.palette.error.main,
    textAlign: 'center',
    lineHeight: 1.43,
    letterSpacing: 0.18,
    marginTop: 8,
  },
  textFieldPadding: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  loginBtnWrapper: {
    marginTop: 0,
    marginBottom: 20,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    width: '100%',
  },
}));

export const SignInForm = ({ variant, email, password }: SignInFormProps) => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [asyncError, setAsyncError] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const partnerService = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );
  const [showPassword, setShowPassword] = useState(false);

  const handleForgotPassword = useCallback(() => {
    navigate('/forgot-password');
  }, [navigate]);

  useEffect(
    () => () => {
      dispatch(AuthActions.resetCredentials());
    },
    [dispatch],
  );

  if (!partnerService) {
    return null;
  }

  return (
    <Formik
      validationSchema={LoginValidation}
      initialValues={!!email && !!password ? { email, password } : { password: '', email: '' }}
      validateOnBlur={false}
      validateOnChange={validateOnChange}
      onSubmit={async (values, formik) => {
        try {
          setAsyncError('');
          localStorage.removeItem('vpp_translation_content_language');
          localStorage.removeItem('publicId');
          sessionStorage.removeItem('ResourceAuth');
          const response = await dispatch(
            AuthActions.Login({ username: values.email, password: values.password }),
          ).unwrap();

          Sentry.setUser({ email: values.email });

          if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            dispatch(AuthActions.Logout());
            Sentry.setUser(null);

            navigate(getInitialPasswordPathname(values.email));
          } else {
            const partnerRoles = await partnerService.getPartnerRoles();
            dispatch(AuthActions.setPartnerRoles(partnerRoles));

            if (partnerRoles.length === 0) {
              setAsyncError('form.login.error.no_partners_available');
              formik.setFieldError('email', ' ');
              formik.setFieldError('password', ' ');
              Sentry.setUser(null);

              dispatch(AuthActions.Logout());
            } else navigate('/dashboard');
          }
        } catch (error) {
          formik.setFieldError('email', ' ');
          formik.setFieldError('password', ' ');
          setAsyncError('form.login.error.authentication_failed');
          setSubmitDisabled(true);
        }
      }}
      validate={() => {
        if (!validateOnChange) {
          setValidateOnChange(true);
        }

        setAsyncError('');
        setSubmitDisabled(false);
      }}>
      {formik => (
        <Form className={classes.form}>
          <ConnectedFocusError />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={clsx({ [classes.textFieldPadding]: variant === 2 })}>
                <Field
                  className={classes.textField}
                  component={FormikTextField}
                  variant='outlined'
                  name='email'
                  type='email'
                  label={
                    <FormattedMessage
                      id='form.login.field.email.label'
                      defaultMessage='E-mail address'
                    />
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            formik.setFieldValue('email', '');
                          }}
                          edge='end'>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormikErrorMessage name='email' />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className={clsx({ [classes.textFieldPadding]: variant === 2 })}>
                <Field
                  className={classes.textField}
                  component={FormikTextField}
                  variant='outlined'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  label={
                    <FormattedMessage
                      id='form.login.field.password.label'
                      defaultMessage='Password'
                    />
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormikErrorMessage name='password' />
              </Box>
            </Grid>

            {asyncError && (
              <Grid item xs={12}>
                <Typography variant='body2' className={classes.error}>
                  <FormattedMessage
                    id={asyncError}
                    defaultMessage='Email or password is incorrect.'
                  />
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Box
                className={clsx(classes.btnWrapper, {
                  [classes.loginBtnWrapper]: variant === 2,
                })}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={submitDisabled || !formik.isValid}
                  className={clsx(classes.mainActionBtn, classes.userBtn)}>
                  <FormattedMessage id='form.login.cta' defaultMessage='Login' />
                </Button>
              </Box>
            </Grid>

            {variant === 1 && (
              <Grid item xs={12}>
                <Box className={classes.btnWrapper}>
                  <Button
                    variant='text'
                    onClick={handleForgotPassword}
                    className={clsx(classes.userBtn, classes.forgetBtn)}>
                    <FormattedMessage
                      id='form.login.forgot_password'
                      defaultMessage='Password forgotten?'
                    />
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
