import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { Button, Dropzone, UploadIndicator } from '@/Components';
import { useAppContext } from '@/Context';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { useUploadCancel } from '@/Hooks/useUploadCancel';
import { AssetServiceFactory } from '@/Services';
import ErrorIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import ChangeIcon from '@/Static/Icons/atoms-symbols-icons-media-change.svg';
import { GalleryAsset, Partner } from '@/Types';
import { handleFileUpload } from '@/Utils/fileUpload';

import { GalleryAssetPreview } from '../Forms/Assets/GalleryAssetPreview';
import { useAlterGalleryImageStyles } from './Styles/AlterGalleryImage.styles';

interface AlterGalleryImageModalProps {
  resourceData: GalleryAsset;
  casPublicId: Partner['casPublicId'];

  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;

  idx: number;
}

export const AlterGalleryImageModal = (props: AlterGalleryImageModalProps) => {
  const { casPublicId, resourceData, handleReplaceImage, idx } = props;

  const [open, setOpen] = useState(false);
  const { classes } = useAlterGalleryImageStyles();
  const { formatMessage } = useMemoedIntl();
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const [isWrongFormat, setIsWorngFormat] = useState(false);
  const { dispatch } = useAppContext();
  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);
  const [uploadedNewAsset, setUploadedNewAsset] = useState<GalleryAsset>();

  const { uploads, handleCancel, setUploads } = useUploadCancel();
  const handleDrop = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const file = files[0];

        await handleFileUpload(
          assetService,
          casPublicId,
          file,
          'Gallery',
          setUploads,
          async (filename: string, file: File) => {
            const res = await assetService.assignGalleryImage(casPublicId, {
              filename,
              originalFilename: file.name,
            });

            setUploadedNewAsset({
              uri: filename,
              assetId: res.data.assetId,
              originalFilename: file.name,
              title: {
                en: '',
                de: '',
              },
            });
          },
          formatMessage({
            id: 'upload.failed',
            defaultMessage: 'Upload failed',
          }),
        );
      }
    },
    [assetService, casPublicId, formatMessage, setUploads],
  );

  const handleApplyChanges = useCallback(() => {
    uploadedNewAsset &&
      uploadedNewAsset.uri &&
      uploadedNewAsset.assetId &&
      handleReplaceImage(
        idx,
        uploadedNewAsset.uri,
        uploadedNewAsset.assetId,
        uploadedNewAsset.originalFilename,
      );

    handleClose();
  }, [uploadedNewAsset, handleReplaceImage, idx, handleClose]);

  return (
    <>
      <Button className={classes.btnModal} onClick={handleOpen} variant='text'>
        <img alt='' src={ChangeIcon} />
        <Typography variant='body2' className={classes.btnText}>
          {formatMessage({
            id: 'alter.gallery.image.cta.replace_image',
            defaultMessage: 'Replace image',
          })}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            padding: '50px 30px 30px 30px',
            maxWidth: 'unset',
            width: 696,
          },
        }}>
        <DialogTitle classes={{ root: 'dialogImgTitle' }} className={classes.dialogImgTitle}>
          <Box className={classes.headingWrapper}>
            {formatMessage({
              id: 'alter.gallery.image.cta.replace_image',
              defaultMessage: 'Replace image',
            })}
            <IconButton aria-label='close' onClick={handleClose}>
              <img alt='' src={CloseIcon} />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={4}>
            <FormSnackbar
              openToastMessage={isWrongFormat}
              handleCloseToastMessage={() => setIsWorngFormat(false)}
              customMessage={formatMessage({
                id: 'toast.upload.fail',
                defaultMessage:
                  'File upload failed. Please verify the file type and size and try again.',
              })}
              customIcon={ErrorIcon}
            />
            <Grid item xs={5}>
              <GalleryAssetPreview
                casPublicId={casPublicId}
                resourceData={resourceData}
                isBinIcon={false}
              />
            </Grid>
            <Grid item xs={7}>
              {uploadedNewAsset && (
                <GalleryAssetPreview
                  casPublicId={casPublicId}
                  resourceData={uploadedNewAsset}
                  handleRemoveNewImage={() => {
                    setUploadedNewAsset(undefined);
                    setUploads([]);
                  }}
                  isBinIcon
                />
              )}
              {uploads.length > 0 && (
                <UploadIndicator
                  uploads={uploads}
                  width={195}
                  handleCancel={handleCancel}
                  removeButton={false}
                />
              )}
              {!uploads.length && (
                <Box className={classes.dropzoneWrapper}>
                  <Dropzone
                    disabled={false}
                    maxFileSize={15000000}
                    supportedFileNames='JPEG, PNG, TIFF'
                    onDrop={handleDrop}
                    onDropRejected={() => setIsWorngFormat(true)}
                    accept='image/jpeg, image/png, image/tiff'
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button autoFocus disabled={!uploadedNewAsset} onClick={handleApplyChanges}>
            {formatMessage({
              id: 'alter.gallery.image.cta.apply',
              defaultMessage: 'Apply changes',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
