import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CtaContainer } from '@/App/Shared/DataGridTable/Table/NoRowsOverlay.styles';
import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { BodyTextRegular, Button, PageHeadline, SpinnerLoader } from '@/Components';
import useCurrentCheckins from '@/Hooks/useCurrentCheckins';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { useGetPartnerQuery } from '@/Queries';
import { checkInManually } from '@/Services/CheckInManually.service';
import { ReactComponent as BluePlusIcon } from '@/Static/Icons/bluePlusIcon.svg';
import { uiActions } from '@/Store';
import { ManualCheckInputType } from '@/Types';
import { checkInSorter } from '@/Utils';

import CheckInRow from './CurrentCheckinRow';
import useStyles from './CurrentCheckinsWidget.styles';

const CurrentCheckinsWidget = () => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const language = useCurrentLanguage();

  const [isShowToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | undefined>();
  const queryClient = useQueryClient();

  const { data: currentCheckins = [], isLoading } = useCurrentCheckins();
  const { data: partnerData } = useGetPartnerQuery();

  const checkInsRows = useMemo(() => {
    const uniqueCheckIns = currentCheckins.reduce((acc, checkIn) => {
      const { checkInId, checkOutTimestamp } = checkIn.data;
      const existingCheckIn = acc.get(checkInId);

      if (checkOutTimestamp !== null || !existingCheckIn) {
        acc.set(checkInId, checkIn);
      }

      return acc;
    }, new Map());

    return Array.from(uniqueCheckIns.values()).sort(checkInSorter).slice(0, 8);
  }, [currentCheckins]);

  const handleCheckInSubmit = useCallback(
    async (data: ManualCheckInputType) => {
      if (!partnerData?.publicId) return;

      const referenceId = uuidv4();

      try {
        const response = await checkInManually({
          ...data,
          partnerPublicId: partnerData?.publicId || '',
          referenceId,
        });

        setShowToast(!isShowToast);
        setToastMessage('Check-in has been added');

        queryClient.invalidateQueries(['currentCheckins', partnerData?.publicId]);

        return response;
      } catch (e) {
        throw e;
      }
    },
    [partnerData, isShowToast, setShowToast, setToastMessage, queryClient],
  );

  const handleCloseToastMessage = useCallback(() => {
    setShowToast(false);
    setToastMessage('');
  }, []);

  const showModalPaymentMethod = useCallback(() => {
    dispatch(
      uiActions.showModal('CHECK_IN', {
        handleSubmit: (data: ManualCheckInputType) => handleCheckInSubmit(data),
      }),
    );
  }, [dispatch, handleCheckInSubmit]);

  return (
    <Box className={classes.root}>
      <FormSnackbar
        customMessage={toastMessage}
        openToastMessage={isShowToast}
        handleCloseToastMessage={handleCloseToastMessage}
      />
      <Box className={classes.infoWrapper}>
        <div className={classes.headerContainer}>
          <PageHeadline
            textContent={formatMessage({
              id: 'dash.current_checkins.title',
              defaultMessage: 'Current Check-Ins',
            })}
          />
          <Button
            onClick={showModalPaymentMethod}
            icon={<BluePlusIcon />}
            className={classes.addCheckInButton}
            textTransform>
            {formatMessage({
              id: 'view.current_checkins.cta.add_manual_checkin',
              defaultMessage: 'Add Manual Check-In',
            })}
          </Button>
        </div>
        {isLoading && <SpinnerLoader />}
        {!isLoading &&
          checkInsRows.map(row => (
            <CheckInRow data={row.data} key={row.data.checkInId} className={classes.rowItem} />
          ))}
        {!isLoading && checkInsRows.length === 0 && (
          <div className={classes.nowRowsOverlayBackground}>
            <PageHeadline
              textContent={formatMessage({
                id: 'view.current_checkins.table.empty_table_header',
                defaultMessage: 'No existing check-ins',
              })}
            />
            <BodyTextRegular
              textContent={formatMessage({
                id: 'view.current_checkins.table.empty_table_sub_header',
                defaultMessage: 'Only current check-ins are shown here.',
              })}
            />
            <CtaContainer>
              <Button
                disabled={partnerData?.readonly}
                onClick={() => navigate(`/${language}/statistik/check-ins-alle`)}
                textTransform
                size='sm'>
                {formatMessage({
                  id: 'view.current_checkins.cta.link_to_statistics',
                  defaultMessage: 'Show Statistics',
                })}
              </Button>
            </CtaContainer>
          </div>
        )}
        {checkInsRows.length > 0 && (
          <div className={classes.buttonContainer}>
            <Button onClick={() => navigate(`/${language}/current-checkins`)}>
              {formatMessage({
                id: 'cta.show_all_checkins',
                defaultMessage: ' Show All Check-Ins',
              })}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CurrentCheckinsWidget;
