import { CognitoUser } from 'amazon-cognito-identity-js';

export enum DataFetchingStatus {
  Idle,
  Fetching,
  HasError,
  FulFilled,
}

export interface AuthStateTypes {
  isAuthenticated: boolean;
  fetchingStatus: DataFetchingStatus;
  credentials: { email: string; password: string } | null;
  partnerRoles: { [key: string]: string }[];
  cognitoUser?: CognitoUser;
  errorMessage?: string;
}

export type SelfRegistration = {
  email: string;
  name: string;
  partnerId: string;
  surname: string;
};

export type SelfRegistrationResponseType = {
  detail?: SelfRegistration;
  status: 'SUCCESS' | 'ERROR';
  statusCode: number;
};
