import { Box } from '@mui/material';
import clsx from 'clsx';
import { Field } from 'formik';
import { ReactNode, useEffect, useState } from 'react';

import { FormikTextFieldPhoto } from '@/App/Shared/Form/Components/Formik/FormikTextFieldPhoto';
import { AlterGalleryImageModal, CropGalleryImageModal } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { useGetPartnerQuery } from '@/Queries';
import New from '@/Static/Icons/atoms-symbols-alert-neu.svg';
import { GalleryAsset, Partner } from '@/Types';

import { GalleryAssetPreview } from '../Assets/GalleryAssetPreview';
import { useGalleryImageTileStyles } from './Gallery.styles';

type GalleryImageTileProps = {
  resourceData: GalleryAsset;
  handleRemoveImage: (value: number) => void;
  dragComponent: ReactNode;
  casPublicId: Partner['casPublicId'];
  idx: number;

  latestUploads: GalleryAsset[];
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
};

export const GalleryImageTile = ({
  handleRemoveImage,
  dragComponent,
  resourceData,
  casPublicId,
  idx,

  latestUploads,
  handleReplaceImage,
  handlePrimaryCtaDisabled,
}: GalleryImageTileProps) => {
  const currentLanguage = useCurrentLanguage();
  const { classes } = useGalleryImageTileStyles();
  const [isNew, setIsNew] = useState(false);
  const { data: partner } = useGetPartnerQuery();

  useEffect(() => {
    const isNew = latestUploads.find(upload => upload.assetId === resourceData.assetId);

    setIsNew(!!isNew);
  }, [latestUploads, resourceData]);

  return (
    <Box
      className={clsx(classes.tileWrapper, {
        [classes.tileWrapperDisabled]: !!partner?.readonly,
        [classes.activeBorder]: idx === 0,
      })}>
      {isNew && idx > 0 && <img className={classes.isNew} alt='' src={New} />}
      {!resourceData.assetId && idx === 0 && <Box className={classes.galleryPlaceholder} />}
      {resourceData.assetId && (
        <>
          <Box className={classes.topWrapper}>
            {resourceData.uri && (
              <CropGalleryImageModal
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                resourceData={resourceData}
                casPublicId={casPublicId}
              />
            )}
            {dragComponent}
          </Box>
          <GalleryAssetPreview
            resourceData={resourceData}
            casPublicId={casPublicId}
            handleRemoveImage={handleRemoveImage}
            isBinIcon
          />
          <Box className={classes.tileInputWrapper}>
            <Field
              className={classes.input}
              component={FormikTextFieldPhoto}
              variant='outlined'
              name={`gallery[${idx}].title.${currentLanguage}`}
              type='text'
            />
          </Box>
          <Box className={classes.alterModalBtn}>
            <AlterGalleryImageModal
              casPublicId={casPublicId}
              resourceData={resourceData}
              handleReplaceImage={handleReplaceImage}
              idx={idx}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
